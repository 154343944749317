import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import {
  KdtImportAddPresentedDocument,
  KdtImportAddWareItem,
  KdtImportDeleteWareItems,
  KdtImportReplacePresentedDocuments,
  KdtImportReplaceWares,
  KdtImportReplaceWaresAndDocuments,
  KdtImportUpdateBlock,
  KdtImportUpdatePresentedDocuments,
  KdtImportUpdateWareItems,
  SetKdtImportDocument,
  SetParentDocument,
} from './kdt-import.actions';
import { KdtStateModel } from '../types/kdt-import';

@State<KdtStateModel>({
  name: 'kdt',
  defaults: {
    selectedDocument: null,
    selectedDocumentWares: [],
    parentDocumentWares: [],
  },
})
@Injectable()
export class KdtImportState {
  // TODO: сделать селекторы на каждый блок в отдельности для оптимизации
  @Selector()
  static getSelectedDocument(state: KdtStateModel) {
    return state.selectedDocument;
  }

  @Selector()
  static getSelectedDocumentWares(state: KdtStateModel) {
    return state.selectedDocumentWares;
  }

  @Selector()
  static geParentDocumentWares(state: KdtStateModel) {
    return state.parentDocumentWares;
  }

  @Action(KdtImportUpdateBlock)
  kdtImportUpdateBlock(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportUpdateBlock,
  ) {
    const [key] = Object.keys(action.block);

    ctx.setState(
      produce(draft => {
        if (draft.selectedDocument) {
          draft.selectedDocument[key] = action.block[key];
        }
      }),
    );
  }

  @Action(KdtImportAddPresentedDocument)
  kdtImportAddPresentedDocument(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportAddPresentedDocument,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocumentItem,
        );
      }),
    );
  }

  @Action(KdtImportUpdatePresentedDocuments)
  kdtImportUpdatePresentedDocuments(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportUpdatePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        action.presentedDocuments.forEach(document => {
          const id = document.id;
          const index = ctx
            .getState()
            .selectedDocument?.presentedDocuments.findIndex(i => i.id === id);
          if (index !== null && index !== undefined) {
            draft.selectedDocument?.presentedDocuments.splice(
              index,
              1,
              document,
            );
          }
        });
      }),
    );
  }

  @Action(KdtImportReplacePresentedDocuments)
  kdtImportReplacePresentedDocuments(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportReplacePresentedDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.presentedDocuments,
        );
      }),
    );
  }

  @Action(SetKdtImportDocument)
  setKdtImportDocument(
    ctx: StateContext<KdtStateModel>,
    action: SetKdtImportDocument,
  ) {
    const { wares, ...other } = action.declaration;
    ctx.patchState({
      selectedDocumentWares: wares,
      selectedDocument: other,
    });
  }

  @Action(SetParentDocument)
  setParentDocument(
    ctx: StateContext<KdtStateModel>,
    action: SetParentDocument,
  ) {
    const { wares } = action.declaration;
    ctx.patchState({
      parentDocumentWares: wares,
    });
  }

  @Action(KdtImportAddWareItem)
  kdtImportAddWareItem(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportAddWareItem,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares?.push(action.wareItem);
      }),
    );
  }

  @Action(KdtImportUpdateWareItems)
  kdtImportUpdateWareItems(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportUpdateWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        action.wareItems.forEach(wareItem => {
          const id = wareItem.id;
          const index = ctx
            .getState()
            .selectedDocumentWares.findIndex(i => i.id === id);
          if (index !== null) {
            draft.selectedDocumentWares.splice(index, 1, wareItem);
          }
        });
      }),
    );
  }

  @Action(KdtImportDeleteWareItems)
  kdtImportDeleteWareItems(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportDeleteWareItems,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(KdtImportReplaceWaresAndDocuments)
  kdtImportReplaceWaresAndDocuments(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportReplaceWaresAndDocuments,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocument?.presentedDocuments.splice(0);
        draft.selectedDocument?.presentedDocuments.push(
          ...action.documentsAndWares.presentedDocuments,
        );
        draft.selectedDocumentWares.splice(0);
        draft.selectedDocumentWares.push(...action.documentsAndWares.wares);
      }),
    );
  }

  @Action(KdtImportReplaceWares)
  kdtImportReplaceWares(
    ctx: StateContext<KdtStateModel>,
    action: KdtImportReplaceWares,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedDocumentWares = action.wareItems;
      }),
    );
  }
}
